import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import FormContent from './formContent';
import Aside from './aside';

class Content extends React.PureComponent {
    state = {};

    render() {
        return (
            <div className={styles.main}>
                {/* 登录表单 */}
                <FormContent />
                {/* 第三方登录 */}
                <Aside />
            </div>
        );
    }
}

export default errorBoundary(Content);
