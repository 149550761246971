import React from 'react';
import styles from './index.css';
import { cookie } from '@ifeng-fe/ui_base';

import errorBoundary from '@ifeng-fe/errorBoundary';
import { USERAPI } from '../../../../../services/api';
import { formatSearchParams } from '../../../../../utils/utils';

// const linksUrl = {
//     linkwx:
//         'https://open.weixin.qq.com/connect/qrconnect?appid=wx10ce490118943a33&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fweixin&response_type=code&scope=snsapi_login&state=',
//     linksina:
//         'https://api.weibo.com/oauth2/authorize?client_id=1073104718&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fsina&response_type=code',
//     linkqq:
//         'https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=100514926&redirect_uri=https://id.ifeng.com/callback/qzone&state=8bc42edbdbd6e37dedd1cee53f009b7f&scope=get_user_info,add_share,list_album,add_album,upload_pic,add_topic,add_one_blog,add_weibo,check_page_fans,add_t,add_pic_t,del_t,get_repost_list,get_info,get_other_info,get_fanslist,get_idolist,add_idol,del_idol,get_tenpay_addr',
// };

const linksUrl = `${USERAPI}/api/v1/get/authorizeurl`;

class ThirdParty extends React.PureComponent {
    static propTypes = {};

    OTKTIMER = null;

    state = { searchParams: (window.location.search && formatSearchParams(window.location.search)) || {} };

    checkOTK() {
        cookie.del('IF_OTK', '/', '.ifeng.com');
        clearInterval(this.OTKTIMER);

        this.OTKTIMER = setInterval(() => {
            const otk = cookie.get('IF_OTK');

            if (otk.trim()) {
                clearInterval(this.OTKTIMER);
                try {
                    const result = new Function(`return ${otk}`)();

                    cookie.del('IF_OTK', '/', '.ifeng.com');

                    if (result.code === 1 || result.code === 'OK') {
                        location.href = '/security';
                    } else if (result) {
                        alert(result.message || '第三方登录失败，请刷新页面重试');
                    }
                } catch (error) {
                    cookie.del('IF_OTK', '/', '.ifeng.com');
                    alert('第三方登录失败，请刷新页面重试');
                }
            } else {
                const sid = cookie.get('sid');

                if (sid) {
                    clearInterval(this.OTKTIMER);
                    const cb = this.searchParams.cb;

                    if (cb) {
                        location.href = encodeURIComponent(cb);
                    } else {
                        location.href = '/security';
                    }
                }
            }
        }, 300);
    }

    handleClick = type => {
        cookie.del('sid', '/', '.ifeng.com');
        const linkurl = `${linksUrl}?sns=${type}&platform=w&systemid=13`;

        window.open(linkurl, '', 'width=770, height=630');

        this.checkOTK();
    };

    render() {
        return (
            <div className={styles.aside}>
                <h3>
                    还没账号？<a href="//user.ifeng.com/register" target="_blank">
                        <em>请注册</em>
                    </a>
                </h3>
                <h3>
                    <a href="/findpass" target="_blank">
                        <em>忘记密码？</em>
                    </a>
                </h3>
                <p className={styles.gray}>第三方账号登录</p>
                <div className="clearfix">
                    <div className={styles.item}>
                        <div className={styles.linkwx} onClick={this.handleClick.bind(this, 'weixin')} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.linkqq} onClick={this.handleClick.bind(this, 'qzone')} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.linkweibo} onClick={this.handleClick.bind(this, 'sina')} />
                    </div>
                </div>
            </div>
            // <div className={styles.thirdParty}>
            //     <div className={styles.btnBox}>
            //         <div className={styles.btnWrapper}>
            //             <div className={styles.linkwx} onClick={this.handleClick.bind(this, 'linkwx')} />
            //         </div>
            //         <div className={styles.btnWrapper}>
            //             <div className={styles.linkqq} onClick={this.handleClick.bind(this, 'linkqq')} />
            //         </div>
            //         <div className={styles.btnWrapper}>
            //             <div className={styles.linkweibo} onClick={this.handleClick.bind(this, 'linksina')} />
            //         </div>
            //     </div>
            // </div>
        );
    }
}

export default errorBoundary(ThirdParty);
