import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import { FormItem } from '../../../../../../components/formReducer';
import Input from '../../../../components/input';
import UserName from './userName';
import Password from './password';
import VCode from './vCode';
import AutoLogin from './autoLogin';
import { loginPost, cacheUserInfo } from '../../../../../../services/api';
import { cookie } from '@ifeng-fe/ui_base';
import { formatSearchParams } from '../../../../../../utils/utils';

class LoginById extends React.PureComponent {
    static propTypes = {};

    state = {
        fieldsValue: {},
        fieldsError: {},
        names: [],
        rules: [],
        error: '',

        captcha_id: '',
        searchParams: (window.location.search && formatSearchParams(window.location.search)) || {},
    };

    // 接收表单返回值
    handleChange = value => {
        const { fieldsValue } = this.state;

        fieldsValue[value.fieldsName] = value.fieldsValue;
        const newfieldsValue = JSON.parse(JSON.stringify(fieldsValue));

        this.setState({
            fieldsValue: newfieldsValue,
        });
    };

    // 接收错误信息
    handleError = error => {
        const { fieldsError } = this.state;

        fieldsError[error.fieldsName] = error.fieldsError;
        const newfieldsError = JSON.parse(JSON.stringify(fieldsError));

        this.setState({
            error,
            fieldsError: newfieldsError,
        });
    };

    // 清除指定错误信息
    clearErrors = name => {
        const { fieldsError, error } = this.state;
        const newFieldsError = JSON.parse(JSON.stringify(fieldsError));

        if (newFieldsError.hasOwnProperty(name)) {
            delete newFieldsError[name];
        }
        this.setState(
            {
                fieldsError: newFieldsError,
            },
            () => {
                if (error.fieldsName === name) {
                    this.setState({
                        error: '',
                    });
                }
            },
        );
    };

    // 收集校验规则
    selectRules = (name, itemRules) => {
        const { names, rules } = this.state;

        names.push(name);
        rules[name] = itemRules;
        this.setState({
            names,
            rules,
        });
    };

    // 执行全局校验
    runCheck = () => {
        const { rules, fieldsValue } = this.state;

        for (const name in rules) {
            if (rules.hasOwnProperty(name)) {
                const itemRules = rules[name];

                this.handleCheck(fieldsValue[name], itemRules, name);
            }
        }
    };

    // 全局错误中转
    errCallBack = (name, error) => {
        const { fieldsError, names } = this.state;

        fieldsError[name] = error;
        const newfieldsError = JSON.parse(JSON.stringify(fieldsError));

        this.setState(
            {
                fieldsError: newfieldsError,
            },
            () => {
                for (let i = 0; i < names.length; i++) {
                    const item = names[i];

                    if (newfieldsError[item]) {
                        this.setState({
                            error: {
                                fieldsName: item,
                                fieldsError: newfieldsError[item],
                            },
                        });
                        break;
                    }
                }
            },
        );
    };

    // 处理全局校验
    handleCheck = (value, rules, name) => {
        for (let i = 0; i < rules.length; i++) {
            const item = rules[i];

            // 必填项
            if (item.hasOwnProperty('required') && item.required === true) {
                if (!value) {
                    this.errCallBack(name, item.message);
                    console.warn(`fieldsError:[${name}] ===== ${item.message}`);
                    break;
                } else {
                    this.clearErrors(name);
                }
            }

            // 正则校验
            if (item.hasOwnProperty('pattern')) {
                const pattern = item.pattern;

                if (!pattern.test(value)) {
                    this.errCallBack(name, item.message);
                    console.warn(`fieldsError:[${name}] ===== ${item.message}`);
                    break;
                } else {
                    this.clearErrors(name);
                }
            }

            // 自定义检验函数
            if (item.hasOwnProperty('validator')) {
                let flag = false;
                const validator = item.validator;
                const callBack = message => {
                    if (message) {
                        this.errCallBack(name, message);
                        console.warn(`fieldsError:[${name}] ===== ${message}`);
                        flag = true;
                    } else {
                        flag = false;
                        this.clearErrors(name);
                    }
                };

                validator(value, callBack);
                if (flag) {
                    break;
                }
            }
        }
    };

    refreshVCode = null;

    // 绑定获取验图片证码
    bindGetCode = cb => {
        this.refreshVCode = cb;
    };

    // 获取图片验证码id
    getImgCodeId = codeId => {
        this.setState({
            captcha_id: codeId,
        });
    };

    // 提交登录
    handleSubmmit = () => {
        this.runCheck();

        const { fieldsValue, fieldsError, captcha_id } = this.state;

        console.log(fieldsValue, fieldsError);

        if (!fieldsError || !Object.keys(fieldsError).length) {
            const { account, password, captcha_code } = fieldsValue;

            const params = {
                account,
                password,
                captcha_code,
                captcha_id,
            };

            console.log(params);

            this.asyncLoginPost(params, this.loginFailCallbak);
        }
    };

    loginFailCallbak = (msg, tip) => {
        // console.log(msg);

        if (msg && msg === 'CaptchaNotMatch') {
            this.handleError({
                fieldsName: 'captcha_code',
                fieldsError: tip,
            });
        } else if (msg && msg === 'WrongPassword') {
            this.handleError({
                fieldsName: 'password',
                fieldsError: '密码错误！',
            });
        } else if (msg && msg === 'AccountNotRegistered') {
            this.handleError({
                fieldsName: 'account',
                fieldsError: '用户名未找到！',
            });
        } else {
            alert(tip);
        }
        this.refreshVCode();
    };

    // 登录请求
    asyncLoginPost = async (params, failCallbak) => {
        const res = await loginPost(1, params, failCallbak);

        if (res) {
            this.setState({
                error: {},
            });

            clearInterval(this.LOGINTIMER);
            this.LOGINTIMER = setInterval(() => {
                const sid = cookie.get('sid');

                if (sid) {
                    // console.log(sid);

                    clearInterval(this.LOGINTIMER);
                    cacheUserInfo().then(res => {
                        const cb = this.state.searchParams && this.state.searchParams.cb;

                        if (cb) {
                            // location.href = encodeURIComponent(cb);
                            location.href = decodeURIComponent(cb);
                        } else {
                            const url = './security';

                            location.href = url;
                        }
                    });
                }
            }, 100);
        }
    };

    render() {
        const { error, fieldsError } = this.state;

        const config = {
            onChange: this.handleChange,
            onBlur: this.handleChange,
            onError: this.handleError,
            clearErrors: this.clearErrors,
            callbackRules: this.selectRules,
            fieldsError,
        };

        return (
            <div className={styles.loginById}>
                <div>
                    <FormItem
                        {...config}
                        name={'account'}
                        validateTrigger={'blur'}
                        rules={[
                            {
                                required: true,
                                message: '请输入账号!',
                            },
                        ]}>
                        <UserName onKeydown={this.handleSubmmit} />
                    </FormItem>
                    <FormItem
                        {...config}
                        name={'password'}
                        validateTrigger={'blur'}
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                        ]}>
                        <Password onKeydown={this.handleSubmmit} />
                    </FormItem>
                    <div>
                        <FormItem
                            {...config}
                            name={'captcha_code'}
                            validateTrigger={'blur'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入验证码!',
                                },
                            ]}>
                            <VCode
                                bindGetCode={this.bindGetCode}
                                getImgCodeId={this.getImgCodeId}
                                onKeydown={this.handleSubmmit}
                            />
                        </FormItem>
                    </div>
                    <FormItem {...config} name={'auto'}>
                        <AutoLogin />
                    </FormItem>
                </div>
                <div className={styles.submmitBtn} onClick={this.handleSubmmit}>
                    登录
                </div>
            </div>
        );
    }
}

export default errorBoundary(LoginById);
