import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import PropTypes from 'prop-types';

import Input from '../../../../../components/input';

import { getVerificationCode } from '../../../../../../../services/api';

class VCode extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.string,
        error: PropTypes.bool,
        bindGetCode: PropTypes.func,
        getImgCodeId: PropTypes.func,
        errorMessage: PropTypes.any,
        onKeydown: PropTypes.func,
    };

    state = {
        vCode: '',
    };

    componentDidMount = () => {
        this.props.bindGetCode(this.refreshVCode.bind(this));

        this.asyncGetVCode();
    };

    asyncGetVCode = async () => {
        const res = await getVerificationCode();

        if (res) {
            this.setState(
                {
                    vCode: res.image,
                },
                () => {
                    this.props.getImgCodeId(res.id);
                },
            );
        }
    };

    refreshVCode = () => {
        this.asyncGetVCode();
    };

    render() {
        const { vCode } = this.state;
        const { onChange, onBlur, value, error, errorMessage } = this.props;
        const config = {
            onChange,
            onBlur,
            value,
            error,
        };

        return (
            <div className={styles.vCode}>
                <label className={styles.vCodeInput}>
                    <span className={styles.label}>验证码</span>
                    <div className={styles.input}>
                        <Input {...config} placeholder={'验证码'} onKeydown={this.props.onKeydown} />
                    </div>
                </label>
                <div className={styles.codeImg} onClick={this.refreshVCode}>
                    <img src={vCode} alt="看不清? 换一个" title="看不清? 换一个" data-fid="fb886ad024" />
                </div>
                <div className={styles.refresh} onClick={this.refreshVCode}>
                    换一个
                </div>
                {error ? <div className={styles.error}>{errorMessage}</div> : null}
            </div>
        );
    }
}

export default errorBoundary(VCode);
