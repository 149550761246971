import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

// import Tab from './tab';
import LoginById from './loginById';
// import LoginByPhone from './loginByPhone';

class FormContent extends React.PureComponent {
    static propTypes = {};

    state = {
        index: 1,
    };

    // handleOnchange = index => {
    //     this.setState({
    //         index,
    //     });
    // };

    render() {
        const { index } = this.state;

        return (
            <div className={styles.formContent}>
                <h2 className={styles.title}>登录凤凰通行证</h2>
                <div className={styles.box}>
                    <div className={styles.formList}>
                        <LoginById />
                    </div>
                    <div className={styles.bot} />
                </div>

                {/* {index === 0 ? <LoginById /> : <LoginByPhone />} */}
            </div>
        );
    }
}

export default errorBoundary(FormContent);
