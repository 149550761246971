import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class CheckBox extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.any,
        style: PropTypes.object,
    };

    state = {
        isChecked: this.props.value || false,
        value: this.props.value,
        onChange: this.props.onChange,
    };

    // static defaultProps = {
    //     value: true,
    // };

    static getDerivedStateFromProps(nextProps, state) {
        const { onChange, style, value } = state;
        let resOnChange = {};
        let resStyle = {};
        let resValue = {};

        if (nextProps.onChange !== onChange) {
            resOnChange = {
                onChange: nextProps.onChange,
            };
        }
        if (nextProps.style !== style) {
            resStyle = {
                style: nextProps.style,
            };
        }
        if (nextProps.value) {
            resValue = { value: nextProps.value };
        } else {
            resValue = { value: false };
        }

        return {
            ...resOnChange,
            ...resStyle,
            ...resValue,
        };
    }

    componentDidMount = () => {
        this.handleChange();
    };

    handleChange = e => {
        this.setState(
            {
                isChecked: !this.state.isChecked,
            },
            () => {
                if (this.state.isChecked) {
                    this.state.onChange(this.state.isChecked);
                } else {
                    this.state.onChange();
                }
            },
        );
    };

    render() {
        const { isChecked } = this.state;

        return (
            <React.Fragment>
                <div className={styles.autoLogin}>
                    <label className={styles.checkBox}>
                        <input type="checkbox" checked={!!isChecked} onChange={this.handleChange} />
                        <span className={styles.txt}>两周内自动登录</span>
                    </label>
                </div>
            </React.Fragment>
        );
    }
}

export default errorBoundary(CheckBox);
