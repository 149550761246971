import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

const trimFun = str => {
    str = String(str).replace(/\s*/g, '');

    return str;
};

class Input extends React.PureComponent {
    static propTypes = {
        configClass: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        style: PropTypes.object,
        type: PropTypes.string,
        error: PropTypes.bool,
        disabled: PropTypes.bool,
        trimSpace: PropTypes.bool,
        onKeydown: PropTypes.func,
    };

    state = {
        value: this.props.trimSpace ? trimFun(this.props.value) : this.props.value,
        onChange: this.props.onChange,
        configClass: this.props.configClass,
        placeholder: this.props.placeholder,
        error: this.props.error,
        disabled: this.props.disabled,
    };

    static defaultProps = {
        value: '',
        type: 'text',
        trimSpace: false,
    };

    static getDerivedStateFromProps(nextProps, state) {
        const { configClass, onChange, placeholder, style, type, error, disabled, value } = state;
        let resConfigClass = {};
        let resOnChange = {};
        let resPlaceholder = {};
        let resStyle = {};
        let resType = {};
        let resError = {};
        let resDisabled = {};
        let resValue = {};

        if (nextProps.configClass !== configClass) {
            resConfigClass = {
                configClass: nextProps.resConfigClass,
            };
        }
        if (nextProps.onChange !== onChange) {
            resOnChange = {
                onChange: nextProps.onChange,
            };
        }
        if (nextProps.placeholder !== placeholder) {
            resPlaceholder = {
                placeholder: nextProps.placeholder,
            };
        }
        if (nextProps.style !== style) {
            resStyle = {
                style: nextProps.style,
            };
        }
        if (nextProps.type !== type) {
            resType = {
                type: nextProps.type,
            };
        }
        if (nextProps.error !== error) {
            resError = {
                error: nextProps.error,
            };
        }
        if (nextProps.disabled !== disabled) {
            resDisabled = {
                disabled: nextProps.disabled,
            };
        }
        if (nextProps.value && nextProps.value !== value) {
            resValue = {
                value: nextProps.value,
            };
        }

        return {
            ...resConfigClass,
            ...resOnChange,
            ...resPlaceholder,
            ...resStyle,
            ...resType,
            ...resError,
            ...resDisabled,
            ...resValue,
        };
    }

    handleChange(e) {
        const { trimSpace } = this.props;
        const val = e.currentTarget.value;
        const value = trimSpace ? trimFun(val) : val;

        this.setState({
            value,
        });
        this.state.onChange(value);
    }

    handleKeydown = e => {
        if (e.keyCode === 13) {
            this.props.onKeydown();
        }
    };

    render() {
        const { value, configClass, placeholder, style, type, error, disabled } = this.state;

        // console.log(this.props);
        // console.log(this.props.error);

        return (
            <React.Fragment>
                <div
                    className={`${styles.Input} ${error ? styles.error : ''} ${configClass ? configClass : ''}`}
                    style={{ ...style }}>
                    {disabled ? (
                        <input placeholder={placeholder ? placeholder : ''} type={type} disabled value={value} />
                    ) : (
                        <input
                            placeholder={placeholder ? placeholder : ''}
                            type={type}
                            onChange={this.handleChange.bind(this)}
                            value={value}
                            onBlur={this.props.onBlur}
                            onKeyDown={this.handleKeydown}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default errorBoundary(Input);
