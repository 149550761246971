import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import PropTypes from 'prop-types';

import Input from '../../../../../components/input';

class Password extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.string,
        error: PropTypes.bool,
        errorMessage: PropTypes.any,
        onKeydown: PropTypes.func,
    };

    state = {};

    render() {
        const { onChange, onBlur, value, error, errorMessage } = this.props;
        const config = {
            onChange,
            onBlur,
            value,
            error,
            errorMessage,
        };

        return (
            <div className={styles.password}>
                <label>
                    <span className={styles.label}>密码</span>
                    <div className={styles.input}>
                        <Input
                            type={'password'}
                            {...config}
                            placeholder={'请输入密码'}
                            onKeydown={this.props.onKeydown}
                        />
                    </div>
                </label>
                {error ? <div className={styles.error}>{errorMessage}</div> : null}
            </div>
        );
    }
}

export default errorBoundary(Password);
