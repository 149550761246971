import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import PropTypes from 'prop-types';

import Input from '../../../../../components/input';

class UserName extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.string,
        error: PropTypes.bool,
        errorMessage: PropTypes.any,
        onKeydown: PropTypes.func,
    };

    state = {};

    render() {
        const { onChange, onBlur, value, error, errorMessage } = this.props;
        const config = {
            onChange,
            onBlur,
            value,
            error,
        };

        // console.log(errorMessage);

        return (
            <div className={styles.userName}>
                <label>
                    <span className={styles.label}>账号</span>
                    <div className={styles.input}>
                        <Input {...config} placeholder={'用户名/邮箱/手机号'} onKeydown={this.props.onKeydown} />
                    </div>
                </label>
                {error ? <div className={styles.error}>{errorMessage}</div> : null}
            </div>
        );
    }
}

export default errorBoundary(UserName);
