import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import { cookie } from '@ifeng-fe/ui_base';

import Header from './header';
import Content from './content';
import Footer from './footer';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();

        const sid = cookie.get('sid');

        // console.log(sid);

        if (sid) {
            location.href = './security';
        }
    }

    render() {
        const { content } = this.props;

        return (
            <div className={styles.layout}>
                <Header />
                <Content />
                <Footer content={content.footer} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
