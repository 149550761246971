import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Header extends React.PureComponent {
    static propTypes = {};

    state = {};

    // 全站渠道标记传递，文章页的任意跳转

    render() {
        return (
            <div className={styles.header}>
                <div className={'w1000'}>
                    <img
                        src="//x0.ifengimg.com/ucms/2020_24/CCA47B3B2DB299B70CB06FB5500DBE12D248BDB0_w125_h50.gif"
                        width="125"
                        height="50"
                        title="凤凰网"
                        alt="凤凰网"
                    />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Header);
