import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

let getFieldsValue = () => {};
let getFieldsError = () => {};
let validateFields = () => {};
let setFieldsError = () => {};

// 表单
class Form extends React.PureComponent {
    static propTypes = {
        children: PropTypes.any,
        onChange: PropTypes.func,
        onError: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
        onError: () => {},
    };

    state = {
        fieldsValue: {},
        fieldsError: {},
        names: [],
        rules: [],
    };

    emptyFun = () => {};

    componentDidMount = () => {
        getFieldsValue = this.getFieldsValue.bind(this);
        getFieldsError = this.getFieldsError.bind(this);
        validateFields = this.validateFields.bind(this);
        setFieldsError = this.setFieldsError.bind(this);
    };

    // 处理表单项变化
    handleChange = value => {
        const { fieldsValue } = this.state;

        fieldsValue[value.fieldsName] = value.fieldsValue;
        const newfieldsValue = JSON.parse(JSON.stringify(fieldsValue));

        this.setState(
            {
                fieldsValue: newfieldsValue,
            },
            () => {
                this.props.onChange(newfieldsValue, {
                    name: value.fieldsName,
                    value: value.fieldsValue,
                });
            },
        );
    };

    handleBlur = value => {
        const { fieldsValue, rules } = this.state;

        fieldsValue[value.fieldsName] = value.fieldsValue;
        const newfieldsValue = JSON.parse(JSON.stringify(fieldsValue));

        this.setState(
            {
                fieldsValue: newfieldsValue,
            },
            () => {
                this.handleCheck(fieldsValue[value.fieldsName], rules[value.fieldsName], value.fieldsName, true, () => {
                    console.log(this.state.fieldsError);

                    this.props.onChange(newfieldsValue, {
                        name: value.fieldsName,
                        value: value.fieldsValue,
                    });
                });
            },
        );
    };

    // 收集错误信息
    handleError = (error, isRunOnError, blurCallBack = this.emptyFun) => {
        const { fieldsError } = this.state;

        fieldsError[error.fieldsName] = error.fieldsError;
        const newfieldsError = JSON.parse(JSON.stringify(fieldsError));

        this.setState(
            {
                fieldsError: newfieldsError,
            },
            () => {
                if (blurCallBack) {
                    blurCallBack();
                }

                if (!isRunOnError) {
                    this.props.onError(newfieldsError, {
                        name: error.fieldsName,
                        msg: error.fieldsError,
                    });
                }
            },
        );
    };

    // 回调变单项值
    getFieldsValue = name => {
        const { fieldsValue } = this.state;

        if (fieldsValue[name]) {
            return fieldsValue[name];
        } else {
            return null;
        }
    };

    // 回调表单项错误
    getFieldsError = name => {
        const { fieldsError } = this.state;

        if (fieldsError[name]) {
            return fieldsError[name];
        } else {
            return null;
        }
    };

    // 获取表单数据
    validateFields = callback => {
        this.runCheck(true);
        const { fieldsValue, fieldsError, names } = this.state;
        const _fieldsError = {};

        names.forEach(item => {
            if (fieldsError[item]) {
                _fieldsError[item] = fieldsError[item];
            }
        });

        callback(fieldsValue, _fieldsError);
    };

    // 清理错误信息
    clearErrors = (name, blurCallBack = this.emptyFun) => {
        const { fieldsError } = this.state;
        const newFieldsError = JSON.parse(JSON.stringify(fieldsError));

        if (newFieldsError.hasOwnProperty(name)) {
            delete newFieldsError[name];
        }

        this.setState(
            {
                fieldsError: newFieldsError,
            },
            () => {
                if (blurCallBack) {
                    blurCallBack();
                }
            },
        );
    };

    // 收集检验规则
    selectRules = (name, itemRules) => {
        const { names, rules } = this.state;

        names.push(name);
        rules[name] = itemRules;
        this.setState({
            names,
            rules,
        });
    };

    // 运行校验
    runCheck = isRunOnError => {
        const { rules, fieldsValue } = this.state;

        for (const name in rules) {
            if (rules.hasOwnProperty(name)) {
                const itemRules = rules[name];

                this.handleCheck(fieldsValue[name], itemRules, name, isRunOnError);
            }
        }
    };

    // 错误中转
    errCallBack = (name, error, isRunOnError, blurCallBack = this.emptyFun) => {
        const itemError = { fieldsName: name, fieldsError: error };

        this.handleError(itemError, isRunOnError, blurCallBack);
    };

    // 执行校验
    handleCheck = (value, rules, name, isRunOnError, blurCallBack = this.emptyFun) => {
        if (!blurCallBack) blurCallBack = () => {};

        for (let i = 0; i < rules.length; i++) {
            const item = rules[i];

            // 必填项
            if (item.hasOwnProperty('required') && item.required === true) {
                if (!value) {
                    this.errCallBack(name, item.message, isRunOnError, blurCallBack);
                    console.warn(`fieldsError:[${name}] ===== ${item.message}`);
                    break;
                } else {
                    this.clearErrors(name, blurCallBack);
                }
            }

            // 正则校验
            if (item.hasOwnProperty('pattern')) {
                const pattern = item.pattern;

                if (!pattern.test(value)) {
                    this.errCallBack(name, item.message, isRunOnError, blurCallBack);
                    console.warn(`fieldsError:[${name}] ===== ${item.message}`);
                    break;
                } else {
                    this.clearErrors(name, blurCallBack);
                }
            }

            // 自定义检验函数
            if (item.hasOwnProperty('validator')) {
                let flag = false;
                const validator = item.validator;
                const callBack = message => {
                    if (message) {
                        this.errCallBack(name, message, isRunOnError, blurCallBack);
                        console.warn(`fieldsError:[${name}] ===== ${message}`);
                        flag = true;
                    } else {
                        flag = false;
                        this.clearErrors(name, blurCallBack);
                    }
                };

                validator(value, callBack);
                if (flag) {
                    break;
                }
            }
        }
    };

    setFieldsError = error => {
        this.handleError(error);
    };

    render() {
        const { children } = this.props;
        const { fieldsError } = this.state;

        return (
            <React.Fragment>
                {React.Children.map(children, child => {
                    if (child.type.name === 'FormItem') {
                        return React.cloneElement(child, {
                            onChange: this.handleChange,
                            onBlur: this.handleBlur,
                            onError: this.handleError,
                            clearErrors: this.clearErrors,
                            callbackRules: this.selectRules,
                            fieldsError,
                        });
                    } else {
                        return React.cloneElement(child);
                    }
                })}
            </React.Fragment>
        );
    }
}

export { Form, getFieldsValue, getFieldsError, validateFields, setFieldsError };
export default errorBoundary(Form);
